body, 
html {
    overflow-x: hidden;
    font-family: 'globerregular','Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    background: $main-bg-color;
    min-height: 100vh;
    position: relative;
}

* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

// Imagens
img {
    max-width: 100%;
    height: auto;
}

// List
ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

li {
    list-style: none;
}

// Container
.container {
    width: $widthContainer;
    max-width: 100%;
    margin: 0 auto;
    position: relative;

    @media(max-width: 998px) {
        padding: 0 30px;
    }

    @media(max-width: 671px) {
        padding: 0 15px;
    }
}