@import '../../assets/css/index';

.container-404 {
    width: 100vw;
    height: calc(100vh - 156px);
    @include displayFlex(column, center, center, center);

    svg {
        width: 100%;
        height: auto;
    }

    h2 {
        font-size: 60px;
        font-weight: bold;
        letter-spacing: 0.69px;
        text-align: center;
        margin: 0;
        color: #656565;
        height: 70px;
        padding: 0;
    }
    
    span {
        display: block;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.34px;
        text-align: center;
        margin: 0;
        color: #656565;
    }

    @media(max-width: 998px) {
        height: calc(100vh - 220px);
    }
}