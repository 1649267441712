// VARIABLES //

// ** Container
$widthContainer: 1400px;

// ** colors
$main-bg-color: #f8fbff;
$dark-blue: #1f2939;
$lime-green: #41932a;

// MIXINS //

// ** Display Flex
@mixin displayFlex($direction, $justify, $item, $content) {
    display: flex;
    flex-flow: $direction;
    justify-content: $justify;
    align-items: $item;
    align-content: $content;
}

// ** Font Display
@mixin fontDisplay($family, $size, $weight, $color) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
    color: $color;
}