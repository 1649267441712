@font-face {
    font-family: 'globerbold_italic';
    src: url('../fonts/glober_bold_italic-webfont.woff2') format('woff2'),
         url('../fonts/glober_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globerbold';
    src: url('../fonts/glober_bold-webfont.woff2') format('woff2'),
         url('../fonts/glober_bold-webfont.woff') format('woff'),
         url('../fonts/Glober_Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globerheavy_italic';
    src: url('../fonts/glober_heavy_italic-webfont.woff2') format('woff2'),
         url('../fonts/glober_heavy_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globerheavy';
    src: url('../fonts/glober_heavy-webfont.woff2') format('woff2'),
         url('../fonts/glober_heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globerlight_italic';
    src: url('../fonts/glober_light_italic-webfont.woff2') format('woff2'),
         url('../fonts/glober_light_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'globerlight';
    src: url('../fonts/glober_light-webfont.woff2') format('woff2'),
         url('../fonts/glober_light-webfont.woff') format('woff'),
         url('../fonts/Glober_Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globerregular_italic';
    src: url('../fonts/glober_regular_italic-webfont.woff2') format('woff2'),
         url('../fonts/glober_regular_italic-webfont.woff') format('woff'),
         url('../fonts/Glober_Regular_Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globerregular';
    src: url('../fonts/glober_regular-webfont.woff2') format('woff2'),
         url('../fonts/glober_regular-webfont.woff') format('woff'),
         url('../fonts/Glober_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globersemibold_italic';
    src: url('../fonts/glober_semibold_italic-webfont.woff2') format('woff2'),
         url('../fonts/glober_semibold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'globersemibold';
    src: url('../fonts/glober_semibold-webfont.woff2') format('woff2'),
         url('../fonts/glober_semibold-webfont.woff') format('woff'),
         url('../fonts/Glober_SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}